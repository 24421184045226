import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/logo.png'
import contactIcon from '../../assets/contactIcon.png'
import locationIcon from '../../assets/locationIcon.png'
import shopIcon from '../../assets/shopIcon.png'
import Footer from '../Location/components/Footer'
import './index.scss'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding:'20px',
        backgroundColor: '#fff',
        alignItems: 'center',
        minHeight: 'calc(100vh - 150px)'
    },
    logo: {
        maxWidth: '250px',
        width: '100%'
    },
    title: {
        fontSize: '27px',
        fontWeight: 'bold',
        lineHeight: '32px',
        margin: '25px 0'
    },
    bodyText: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '131%',
        margin: '10px 0'
    },
    buttonsWrapper: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: '25px 0 0 0'
    },
    buttonCircle: {
        width: '70px',
        height: '70px',
        borderRadius: '35px',
        backgroundColor: '#d8d8d8'
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    buttonText: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '119%',
        textAlign: 'center',
        margin: '10px 0 0 0'
    },
    iconStyle: {
        width: '100%'
    }
}))

const Home = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const { t } = useTranslation()
    const id = props?.match?.params?.id
    return (
        <div className={classes.wrapper}>
            {/* <div className="container">
                {[...new Array(20)].map((it, i) => <div key={i} className="petal" />)}
                <img src={logo} style={{animation: `spin 1.7s ease-in-out infinite`}} className="logo" alt="" />
            </div> */}
            <img src={logo} style={{animation: `spin 2s ease-in-out`}} className={classes.logo} alt="" />
            <Typography color="textPrimary" className={classes.title} align="center">{t('webMain:thanks')}</Typography>
            <Typography color="textPrimary" align="center" className={classes.bodyText}>{t('webMain:help')}</Typography>
            <div className={classes.buttonsWrapper}>
                <div className={classes.buttonWrapper} onClick={() => history.push('/contact', { id })}>
                    <div className={classes.buttonCircle}>
                        <img src={contactIcon} alt="" className={classes.iconStyle} />
                    </div>
                    <Typography className={classes.buttonText} color="textPrimary">{t('webMain:contact')}</Typography>
                </div>
                <div className={classes.buttonWrapper} onClick={() => history.push('/location', { id })}>
                    <div className={classes.buttonCircle}>
                        <img src={locationIcon} alt="" className={classes.iconStyle} />
                    </div>
                    <Typography className={classes.buttonText} color="textPrimary">{t('webMain:location')}</Typography>
                </div>
                <div className={classes.buttonWrapper} onClick={() => history.push('/shop', { id })}>
                    <div className={classes.buttonCircle}>
                        <img src={shopIcon} alt="" className={classes.iconStyle} />
                    </div>
                    <Typography className={classes.buttonText} color="textPrimary">{t('webMain:shop')}</Typography>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home