import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import {
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
} from '@material-ui/core'
import { sendMessage } from '../../api'
import Footer from '../Location/components/Footer'
// import GeoLocation from "../Location/components/GeolocationButton";
import GeolocationCheckbox from '../Location/components/GeolocationCheckbox'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '27px',
    fontWeight: 'bold',
    lineHeight: '32px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px',
    backgroundColor: '#fff',
    alignItems: 'center',
    minHeight: 'calc(100vh - 150px)',
  },
  bodyText: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '131%',
    margin: '20px 0',
    textAlign: 'center',
  },
  checkboxMargin: {
    display: 'flex',
    margin: '15px 0',
  },
  checkboxLabel: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '131%',
  },
  buttonsWrapper: {
    display: 'flex',
    width: '100%',
    margin: '10px 0',
  },
  link: {
    color: '#878787',
    fontSize: '12px',
    fontWeight: '500',
    margin: '0 5px',
  },
}))

const Shop = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const [checked, setChecked] = useState(false)
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const [geo, setGeo] = useState({})
  const [accessLocation, setAccessLocation] = useState({
    checked: false,
    error: null,
  })
  const id = props?.location?.state?.id

  const send = () => {
    setLoading(true)
    sendMessage(
      {
        type: 'text/geo',
        text,
        geo,
      },
      id
    )
      .then(() => {
        history.push('/thanks')
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const getPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log({ position });
          setGeo({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
          setAccessLocation({ checked: true, error: null })
        },
        (error) => {
          // console.log({ error });
          // ? error.code === error.PERMISSION_DENIED
          const errorText =
            error?.code === 2
              ? 'location:fetchingLocationFailed'
              : error
              ? 'location:locationPermissionRejected'
              : null
          setAccessLocation({
            checked: false,
            error: errorText ? t(errorText) : '',
          })
        }
      )
    } else {
      // console.log("else");
      setAccessLocation({
        checked: false,
        error: t('location:browserSupportError'),
      })
    }
  }

  const onChangeShareGPS = (e) => {
    // console.log({ e, "e.target.checked": e.target.checked });
    if (e.target.checked) {
      getPosition()
    } else {
      setAccessLocation({
        checked: false,
        error: t('location:locationPermissionRejected'),
      })
    }
  }

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       setGeo({
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });
  //     });
  //   }
  // }, []);

  return (
    <div className={classes.wrapper}>
      <Typography color='textPrimary' className={classes.title}>
        {t('webMain:shop')}
      </Typography>
      <Typography className={classes.bodyText} style={{ color: '#E4434F' }}>
        {t('webMain:shopText')}
      </Typography>
      <GeolocationCheckbox
        geoText={t('location:location')}
        {...accessLocation}
        onChange={onChangeShareGPS}
      />
      <TextField
        variant='outlined'
        multiline
        rows={7}
        fullWidth
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <Box className={classes.checkboxMargin}>
        <Checkbox
          color='primary'
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
        <Typography color='textPrimary' className={classes.checkboxLabel}>
          {t('contact:text1')}
          <Link to='/terms' className={classes.link}>
            {' '}
            {t('contact:term_link')}{' '}
          </Link>
          {t('contact:text2')}
          <Link to='/datenschutz' className={classes.link}>
            {' '}
            {t('contact:policy_link')}{' '}
          </Link>{' '}
          {t('contact:text3')}
        </Typography>
      </Box>
      <div className={classes.buttonsWrapper}>
        <Button
          onClick={() => history.goBack()}
          style={{ marginRight: '3px', borderRadius: '10px' }}
          variant='contained'
          color='secondary'
          fullWidth
        >
          {t('contact:back')}
        </Button>
        <Button
          disabled={loading || !checked}
          onClick={send}
          style={{ marginLeft: '3px', borderRadius: '10px' }}
          variant='contained'
          color='primary'
          fullWidth
        >
          {t('contact:send')}
        </Button>
      </div>
      <Footer />
    </div>
  )
}

export default Shop
