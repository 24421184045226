import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Typography, Button } from "@material-ui/core";
import logo from "../../assets/logo.png";
import Footer from "../Location/components/Footer";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		padding: "20px",
		backgroundColor: "#fff",
		alignItems: "center",
		minHeight: "calc(100vh - 150px)",
	},
	logo: {
		maxWidth: "250px",
		width: "100%",
	},
	title: {
		fontSize: "27px",
		fontWeight: "bold",
		lineHeight: "32px",
		margin: "25px 0",
	},
	bodyText: {
		fontWeight: 500,
		fontSize: "18px",
		lineHeight: "131%",
		margin: "10px 0",
	},
	buttonsWrapper: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		alignItems: "center",
		margin: "25px 0 0 0",
		maxWidth: "250px",
	},
	buttonCircle: {
		width: "70px",
		height: "70px",
		borderRadius: "35px",
		backgroundColor: "#d8d8d8",
	},
	buttonWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	buttonText: {
		fontWeight: 500,
		fontSize: "14px",
		lineHeight: "119%",
		textAlign: "center",
		margin: "10px 0 0 0",
	},
	iconStyle: {
		width: "100%",
	},
}));

const Thanks = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const history = useHistory();

	return (
		<div className={classes.wrapper}>
			<img
				src={logo}
				className={classes.logo}
				alt=''
			/>
			<Typography
				color='textPrimary'
				className={classes.title}
				align='center'
			>
				{t("thanks:thanks")}
			</Typography>
			<Typography
				color='textPrimary'
				align='center'
				className={classes.bodyText}
			>{`${t("thanks:messageSent")}\n\n${t("thanks:likeApp")}`}</Typography>
			<div className={classes.buttonsWrapper}>
				<Button
					onClick={() => history.replace("/about")}
					style={{ marginBottom: "5px", borderRadius: "10px" }}
					variant='contained'
					color='primary'
					fullWidth
				>
					{t("thanks:more")}
				</Button>
				<Button
					style={{ marginTop: "5px", borderRadius: "10px" }}
					variant='contained'
					color='secondary'
					fullWidth
				>
					<a
						style={{ color: "inherit", textDecoration: "none" }}
						target='_blank'
						href={"https://play.google.com/store/apps/details?id=com.qrmine"}
					>
						{t("thanks:install")} (Google Play)
					</a>
				</Button>
				<Button
					style={{ marginTop: "10px", borderRadius: "10px" }}
					variant='contained'
					color='secondary'
					fullWidth
				>
					<a
						style={{ color: "inherit", textDecoration: "none" }}
						target='_blank'
						href={"https://apps.apple.com/de/app/qr-mine/id1579315203"}
					>
						{t("thanks:install")} (AppStore)
					</a>
				</Button>
			</div>
			<Footer />
		</div>
	);
};

export default Thanks;
