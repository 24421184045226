import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
    marginTop: "20px",
    width: "100%",
  },
  text: {
    color: "#878787",
    fontSize: "10px",
    fontWeight: "500",
    margin: "0 5px",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Link to="/impressum" className={classes.text}>
        {t("more:imprint")}
      </Link>
      <span className={classes.text}> • </span>
      <Link to="/datenschutz" className={classes.text}>
        {t("more:privacy")}
      </Link>
      <span className={classes.text}> • </span>
      <Link to="/terms" className={classes.text}>
        {t("more:terms")}
      </Link>
    </div>
  );
};

export default Footer;
