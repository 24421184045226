import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import Footer from "../Location/components/Footer";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "20px",
    backgroundColor: "#fff",
    // alignItems: 'center',
    minHeight: "calc(100vh - 150px)",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "32px",
    // margin: '25px 0'
  },
  bodyText: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "131%",
    margin: "20px 0",
    maxWidth: "100%",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  link: {
    color: "#878787",
    fontSize: "18px",
    margin: "0 5px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
}));

const Datenschutz = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const arr = [1, 2, 3, 4, 5, 6, 7, 8];
  const arr1 = [10, 11, 12];
  const arr2 = [14, 15, 16, 17, 18, 19, 20, 21, 22];

  return (
    <div className={classes.wrapper}>
      {arr.map((item) => (
        <React.Fragment key={item}>
          <Typography color="textPrimary" className={classes.title}>
            {t(`dataProtection:title${item}`)}
          </Typography>
          <Typography color="textPrimary" className={classes.bodyText}>
            {t(`dataProtection:text${item}`)}
          </Typography>
        </React.Fragment>
      ))}
      <Typography color="textPrimary" className={classes.title}>
        {t(`dataProtection:title9`)}
      </Typography>
      <Typography color="textPrimary" className={classes.bodyText}>
        {t(`dataProtection:text9`)}
        <Typography component="span">
          <a
            href={`${t("dataProtection:link9")}`}
            className={classes.link}
            target="_blank"
            rel="noreferrer"
          >
            {t("dataProtection:link9")}
          </a>
          {t(`dataProtection:text9_1`)}
        </Typography>
      </Typography>
      {arr1.map((item) => (
        <React.Fragment key={item}>
          <Typography color="textPrimary" className={classes.title}>
            {t(`dataProtection:title${item}`)}
          </Typography>
          <Typography color="textPrimary" className={classes.bodyText}>
            {t(`dataProtection:text${item}`)}
          </Typography>
        </React.Fragment>
      ))}
      <Typography color="textPrimary" className={classes.title}>
        {t(`dataProtection:title13`)}
      </Typography>
      <Typography color="textPrimary" className={classes.bodyText}>
        {t(`dataProtection:text13`)}
        <a
          href={`${t("dataProtection:link13")}`}
          className={classes.link}
          target="_blank"
          rel="noreferrer"
        >
          {t(`dataProtection:link13`)}
        </a>
      </Typography>
      {arr2.map((item) => (
        <React.Fragment key={item}>
          <Typography color="textPrimary" className={classes.title}>
            {t(`dataProtection:title${item}`)}
          </Typography>
          <Typography color="textPrimary" className={classes.bodyText}>
            {t(`dataProtection:text${item}`)}
          </Typography>
        </React.Fragment>
      ))}
      <Footer />
    </div>
  );
};

export default Datenschutz;
